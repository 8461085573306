import {
  closeModal,
  navigate,
  openModal,
  setIsLoadingAddressesFromServer,
  setIsLoadingLocationFromServer,
  setIsUserLoggedIn,
  setLocations,
  setOrganizationFull,
  setPaymentMethods,
  setSavedAddresses,
} from './session.actions';
import { createReducer, pushItem, removeItem } from '../common/reducerUtils';
import { EMPTY_MENU, EMPTY_RESTAURANT } from '../../core/constants';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetIsLoadingAddressesFromServerPayload,
  SetIsLoadingLocationFromServerPayload,
  SetIsUserLoggedInPayload,
  SetLocationsPayload,
  SetPaymentMethodsPayload,
  SetSavedAddressesPayload,
} from './session.actions.types';
import { ExtractPayload, getItemsHash, ItemsHash, Menu, Restaurant } from '@wix/restaurants-client-logic';
import { IPaymentMethod } from '@wix/cashier-payments-widget';
import { Address } from '@wix/ambassador-addresses-web/types';
import type { PartialLocation } from '../../core/oloApi';

interface SessionState {
  restaurant: Restaurant;
  menu: Menu;
  itemsHash: ItemsHash;
  modals: OpenModalPayload[];
  routeUrlOverride?: string;
  paymentMethods: IPaymentMethod[];
  isUserLoggedIn: boolean;
  savedAddresses: Address[];
  defaultAddressId?: string;
  isLoadingAddressesFromServer: boolean;
  isLoadingLocationFromServer: boolean;
  locations: PartialLocation[];
  loggedInUserEmail?: string;
}

const initialState: SessionState = {
  restaurant: EMPTY_RESTAURANT,
  menu: EMPTY_MENU,
  itemsHash: {},
  modals: [],
  paymentMethods: [],
  isUserLoggedIn: false,
  savedAddresses: [],
  isLoadingAddressesFromServer: false,
  isLoadingLocationFromServer: false,
  locations: [],
};

const reducer = createReducer<SessionState>(initialState, {
  [setOrganizationFull.toString()]: (state, payload: ExtractPayload<typeof setOrganizationFull>) => {
    const { restaurant, menu } = payload.organizationFull;
    return {
      ...state,
      restaurant,
      menu,
      itemsHash: getItemsHash(menu),
    };
  },
  [openModal.toString()]: (state, payload: OpenModalPayload) => {
    if (state.modals.find(({ modal }) => modal === payload.modal)) {
      console.warn('attempt to open the same modal twice ', payload.modal);
      return state;
    }
    return {
      ...state,
      modals: pushItem(state.modals, payload),
    };
  },
  [closeModal.toString()]: (state, payload: CloseModalPayload) => {
    return {
      ...state,
      modals: removeItem(
        state.modals,
        state.modals.findIndex(({ modal }) => modal === payload.modal),
      ),
      routeUrlOverride: undefined,
    };
  },
  [navigate.toString()]: (state, payload: NavigatePayload) => {
    return {
      ...state,
      routeUrlOverride: payload.routeUrl,
    };
  },
  [setPaymentMethods.toString()]: (state, payload: SetPaymentMethodsPayload) => {
    return {
      ...state,
      paymentMethods: payload.paymentMethods,
    };
  },
  [setIsUserLoggedIn.toString()]: (state, payload: SetIsUserLoggedInPayload) => {
    return {
      ...state,
      isUserLoggedIn: payload.isLoggedIn,
      loggedInUserEmail: payload.isLoggedIn ? payload.loggedInUserEmail : undefined,
    };
  },
  [setSavedAddresses.toString()]: (state, payload: SetSavedAddressesPayload) => {
    return {
      ...state,
      savedAddresses: payload.addresses,
      defaultAddressId: payload.defaultAddressId,
    };
  },
  [setIsLoadingAddressesFromServer.toString()]: (state, payload: SetIsLoadingAddressesFromServerPayload) => {
    return {
      ...state,
      isLoadingAddressesFromServer: payload.isLoadingAddressesFromServer,
    };
  },
  [setIsLoadingLocationFromServer.toString()]: (state, payload: SetIsLoadingLocationFromServerPayload) => {
    return {
      ...state,
      isLoadingLocationFromServer: payload.isLoadingLocationFromServer,
    };
  },
  [setLocations.toString()]: (state, payload: SetLocationsPayload) => {
    return {
      ...state,
      locations: payload.locations,
    };
  },
});

export default reducer;
